import React, { useRef } from "react";
import { Dropdown } from "@my-scoot/component-library-legacy";

import { makeStyles } from "@material-ui/core/styles";
import { is_desktop } from "utils/Utils";
import { isDeviceIOS } from "common/util";

// Form components use common component and add final form layer on them
// Here this component wraps final form layer over Dropdown

const useStyles = makeStyles(
  () => ({
    helperText: {
      marginTop: "8px !important",
      marginBottom: "unset !important",
    },
    dropdownPoppver: {
      width: ({ popperWidth }) => popperWidth,
    },
  }),
  { name: "CreatorTool" }
);

const ExlyDropdown = (props) => {
  const { input, meta = {}, onChange, label, checked, ...rest } = props;

  const { onChange: inputOnChange, value, ...restInputProps } = input;

  const dropdownRef = useRef(null);

  const classes = useStyles({ popperWidth: dropdownRef.current?.offsetWidth });

  const [isKeyboardVisible, setIsKeyboardVisible] = React.useState(false);

  const isIOS = isDeviceIOS();

  React.useEffect(() => {
    const listener = () => {
      const MIN_KEYBOARD_HEIGHT = 300;

      const isKeyboardOpen =
        !is_desktop &&
        window.screen.height - MIN_KEYBOARD_HEIGHT >
          window.visualViewport.height;

      setIsKeyboardVisible(isKeyboardOpen);
    };

    if (isIOS) window.visualViewport.addEventListener("resize", listener);

    return () => {
      if (isIOS) window.visualViewport.removeEventListener("resize", listener);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      <Dropdown
        dropdownLabel={
          label && {
            label: label,
            size: "small",
            weight: "semi-bold",
          }
        }
        // Do not remove,in IOS dropdown overlaps with keyboard,glitches when keyboard is visible
        disabled={isIOS && isKeyboardVisible}
        error={(meta.error || meta.submitError) && meta.touched}
        helperText={
          (meta.error || meta.submitError) && meta.touched
            ? meta.error || meta.submitError
            : null
        }
        handleChange={(value) => {
          inputOnChange(value);
          if (onChange) {
            onChange(value, input.name);
          }
        }}
        value={value || (checked ? [] : value)}
        helperTextClassName={classes.helperText}
        restMenuProps={{ menu_paper_classname: classes.dropdownPoppver }}
        {...rest}
        {...restInputProps}
        checked={checked}
        multiple={checked}
      />
    </div>
  );
};

export default ExlyDropdown;
