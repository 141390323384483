import React from "react";
import { Textarea } from "@my-scoot/component-library-legacy";

const ExlyTextarea = (props) => {
  const { input, meta, onChange, helperText, hideErrorText, ...rest } = props;

  // @Dev: By default the validation will be on touch (visit + blur) event
  // once validateOnSubmit is passed as false the validation will be on submitFailed (on submit)
  const { validateOnSubmit } = rest;
  let finalValidateCondn = meta.touched;
  if (validateOnSubmit) {
    finalValidateCondn = meta.submitFailed;
  }

  return (
    <Textarea
      error={meta.error && finalValidateCondn}
      helperText={
        meta.error && finalValidateCondn && !hideErrorText
          ? meta.error
          : helperText
      }
      {...input}
      {...rest}
      onChange={(e) => {
        input.onChange(e); //final-form's onChange
        if (onChange) {
          //props.onChange
          onChange(e.target.value, input.name);
        }
      }}
    />
  );
};

export default ExlyTextarea;
