import React from "react";
import OfferingSelect from "common/Components/OfferingSelect";
import { useSelector } from "react-redux";
import { is_empty } from "utils/validations";
import { useOfferings } from "hooks/useOfferings";

const ExlyOfferingSelect = (props) => {
  const {
    input,
    meta,
    onChange,
    helperText,
    offering_status,
    allowCreateOffering,
    hideEmptyCreateOfferingBtn,
    ...rest
  } = props;
  const offeringsData = useSelector((state) => state.schedule);
  const { fetchOfferings } = useOfferings();
  /**
   * fetching isLoading from redux
   */
  const loadingFromRedux = offeringsData[offering_status]?.isLoading || false;
  /**
   * fetching loading from props
   */
  const loadingFromProp = props?.loading || false;
  const [options, setOptions] = React.useState(
    is_empty(offering_status) ? props.options : null
  );
  /**
   * handling loading
   */
  const loading = is_empty(offering_status)
    ? loadingFromProp
    : loadingFromRedux;
  React.useEffect(() => {
    if (is_empty(offering_status)) return;
    fetchOfferings(offering_status);
  }, []);

  React.useEffect(() => {
    if (is_empty(offering_status) && !is_empty(props.options)) {
      setOptions(props.options);
    }
  }, [props.options]);

  React.useEffect(() => {
    if (is_empty(offering_status)) return;
    const offerings = offeringsData[offering_status];
    if (!is_empty(offerings.data)) {
      let temp = offerings?.data.map((listing) => ({
        ...listing,
        id: listing.uuid,
        label: listing.title,
        value: listing.uuid,
      }));
      setOptions(temp);
    }
  }, [offeringsData]);
  return (
    <OfferingSelect
      hideEmptyCreateOfferingBtn={hideEmptyCreateOfferingBtn}
      error={meta?.error && meta?.touched}
      helperText={meta?.error && meta?.touched ? meta.error : helperText}
      options={options || []}
      {...input}
      value={input?.value || (input?.multiple ? [] : "")}
      {...rest}
      onChange={(value, valueOption) => {
        if (input?.onChange) {
          input.onChange(value); //final-form's onChange
        }
        if (onChange) {
          //props.onChange
          onChange(value, input?.name, valueOption);
        }
      }}
      loading={loading}
      allowCreateOffering={allowCreateOffering}
    />
  );
};

export default ExlyOfferingSelect;
