import React, { useMemo, useEffect } from "react";
import { List, SimpleList, useListContext } from "react-admin";
import { useDispatch } from "react-redux";
import {
  creatorToolTheme,
  ThemeProvider,
  MobileTable,
  DesktopTable,
} from "@my-scoot/component-library-legacy";

import useScrollToTop from "../../../hooks/useScrollToTop";
import useStyles from "./ExlyTable.styles";
import {
  EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
  EXLY_GLOBAL__SET_PAGE_TITLE,
  EXLY_GLOBAL__SET_SHOW_APP_BAR,
  EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV,
} from "redux/actions";

import LayoutWrapper from "../../Layouts/LayoutWrapper";
import ExlyTableFilters from "./ExlyTableFilters";
import ExlyTablePagination from "./ExlyTablePagination";
import ExlyTableFooter from "./ExlyTableFooter";
import ExlyFilteredEmptyState from "./ExlyTableFilters/ExlyTableFilterEmptyState";
import { is_empty } from "utils/validations";
import ExlyTableEmptyState from "./ExlyTableEmptyState/ExlyTableEmptyState";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import constants from "constants/constants";
import classnames from "classnames";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import cssStyles from "./ExlyTableStyles.module.css";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import useFeaturesActivationStatus from "features/FeatureActivationStatus/utils/useFeaturesActivationStatus";
import { feature_activation_keys } from "features/FeatureActivationStatus/FeatureActivationStatus.constants";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";

export const ExlyContextTable = (props) => {
  const {
    tableTitle,
    layoutProps = {},
    tabConfig = null,
    selectedTab = null,
    subTabSelected = null,
    statsComponent: StatsComponent,
    tableActionComponent: tableActionComponent,
    tableFilters = null,
    columnConfig = null,
    tabFilterConfig,
    hasMobileFooter = false,
    footerProps = {},
    tableChipsFormatter,
    noExportButton = false,
    forceEmpty = false,
    forceTableRender = false,
    reverseDataOrder = false,
    exlyFilteredEmptyStateProps,
    CustomFilters,
    classes: wrapperClasses,
    showTopBars,
    className,
    customBackAction,
    customHandleExport = null,
    hideAllTab = false,
    showFiltersOnEmptyList = false,
    gridView,
    gridWidth,
    GridComponent,
    tableContainerCustomClassName, // this prop allows us to override tableContainerClassName for desktop table
    stickyHeader = true, // this prop is used to make the table headr sticky, by default true, but can be ovverridden if needed
    setFilters: customFiltersSetter,
    bulkActionComponent,
    showSelectAllcheckBox,
    ...rest
  } = props;
  const {
    data,
    setFilters,
    filterValues,
    loaded,
    loading,
    setSort,
    ...tableRest
  } = useListContext();
  const dispatch = useDispatch();
  const isEmpty =
    forceEmpty || (is_empty(data) && loaded && is_empty(filterValues));

  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop, gridWidth });
  useScrollToTop();
  const showTableActionComponent =
    !!tableActionComponent && !isEmpty && !loading;
  const { learnMoreHref } = layoutProps;

  const Table = useMemo(
    () => (isDesktop ? DesktopTable : MobileTable),
    [isDesktop]
  );

  const rows = useMemo(() => {
    if (!data) return data;
    const dataArr = Object.values(data);
    if (!reverseDataOrder) return dataArr;
    return dataArr.reverse();
  }, [data, reverseDataOrder]);

  useAppLayoutControl({
    values: { learnMoreHref },
  });

  useEffect(() => {
    if (layoutProps.showFixedBars) return;
    dispatch({ type: EXLY_GLOBAL__SET_PAGE_TITLE, payload: tableTitle });
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_APP_BAR, payload: isDesktop });
    dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: false });
    if (customBackAction) {
      dispatch({
        type: EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
        payload: customBackAction,
      });
    }
    return () => {
      dispatch({ type: EXLY_GLOBAL__SET_PAGE_TITLE, payload: "" });
      dispatch({ type: EXLY_GLOBAL__SET_SHOW_APP_BAR, payload: true });
      dispatch({ type: EXLY_GLOBAL__SET_SHOW_BOTTOM_NAV, payload: true });
      dispatch({
        type: EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
        payload: null,
      });
    };
  }, [dispatch, isDesktop, layoutProps.showFixedBars, tableTitle]);

  const filterConfigtoSend =
    isEmpty && !showFiltersOnEmptyList ? [] : tabFilterConfig;

  return (
    <LayoutWrapper
      title={tableTitle}
      tabConfig={tabConfig}
      subTabSelected={subTabSelected}
      selectedTab={selectedTab}
      {...layoutProps}
    >
      {/* checks if StatsComponent  exists, render it*/}
      {!!StatsComponent && <StatsComponent />}
      <div
        className={
          bulkActionComponent
            ? cssStyles.showTableActionComponentWrapper
            : `d-flex justify-content-between`
        }
      >
        {!!tableFilters && tableFilters.length && (
          <div
            className={
              showTableActionComponent ? "float-md-left" : "flex-grow-1"
            }
          >
            <ExlyTableFilters
              filters={tableFilters}
              tabFilterConfig={filterConfigtoSend}
              setFilters={customFiltersSetter ?? setFilters}
              filterValues={filterValues}
              columnConfig={columnConfig}
              tableTitle={tableTitle}
              tableChipsFormatter={tableChipsFormatter}
              noExportButton={noExportButton}
              isDesktop={isDesktop}
              hasTabs={!!tabConfig}
              hideFilters={isEmpty}
              rows={rows}
              CustomFilters={CustomFilters}
              showTopBars={showTopBars}
              wrapperClasses={wrapperClasses}
              className={className}
              customHandleExport={customHandleExport}
              hideAllTab={hideAllTab}
              isEmpty={is_empty(data)}
              bulkActionComponent={showSelectAllcheckBox && bulkActionComponent}
              showSelectAllcheckBox={showSelectAllcheckBox}
              {...rest}
            />
          </div>
        )}
        {showTableActionComponent && isDesktop && (
          <div className={`float-right flex-grow-1`}>
            {tableActionComponent}
          </div>
        )}
        {(isDesktop || !showSelectAllcheckBox) && bulkActionComponent}
      </div>
      {isEmpty && !forceTableRender ? (
        <ExlyTableEmptyState {...rest} />
      ) : is_empty(data) && loaded && !forceTableRender ? (
        <ExlyFilteredEmptyState {...exlyFilteredEmptyStateProps} />
      ) : gridView && !is_empty(GridComponent) ? (
        <SimpleList
          className={`${classes.simpleList} d-flex flex-wrap justify-content-center justify-content-md-start`}
          rowStyle={() => ({ padding: 0 })}
          linkType={false}
          primaryText={(record, id) => (
            <GridComponent id={id} record={record} />
          )}
        />
      ) : (
        <Table
          uniqueRowIdFieldName="id"
          rows={rows}
          loading={loading}
          columns={columnConfig}
          // tableContainerClassName & stickyHeader props are only used by DesktopTable.
          tableContainerClassName={classnames(
            classes.desktopTableWrapper,
            tableContainerCustomClassName
          )}
          stickyHeader={stickyHeader}
          onSortOrderChange={(field, order) =>
            setSort(field, order?.toUpperCase())
          }
          {...tableRest}
          {...rest}
        />
      )}
      {!isDesktop && hasMobileFooter && (
        <ExlyTableFooter data={data} {...footerProps} />
      )}
    </LayoutWrapper>
  );
};

/**
 * Developers and reviewers need to ensure that any new updates
 * in table component are updated in both V2 and V1 tables
 */
const ExlyTableWrapper = ({ ra_props, forceEmptyState = false, ...props }) => {
  const {
    exlyTableRootClass = "",
    layoutDesktopMargin,
    layoutMobileMargin,
    noMobileBoxShadow,
    noShadow,
  } = props.layoutProps || {};

  const isDesktop = useIsDesktop();

  const { loading, featuresActivationStatus } = useFeaturesActivationStatus();

  const classes = useStyles({
    margin: isDesktop ? layoutDesktopMargin : layoutMobileMargin,
    noBoxShadow: (noMobileBoxShadow && !isDesktop) || noShadow,
    isDesktop,
  });

  const isPremium =
    !loading &&
    featuresActivationStatus[feature_activation_keys.is_premium.key];

  const perPageOptions =
    ra_props?.perPageOptions !== undefined
      ? ra_props?.perPageOptions
      : isPremium
      ? constants.per_page_options
      : null;

  const wrappedEmptyState = (
    <ThemeProvider theme={creatorToolTheme}>
      <div className={classes.emptyStateWrapper}>
        <ExlyContextTable {...props} forceEmpty={true} />
      </div>
    </ThemeProvider>
  );

  if (forceEmptyState) return wrappedEmptyState;

  return (
    <div className={`${classes.exlyTableRoot} ${exlyTableRootClass}`}>
      <List
        {...ra_props}
        perPage={DEFAULT_PER_PAGE}
        component="div"
        empty={wrappedEmptyState}
        classes={{
          content: classes.listContentRoot,
          bulkActionsDisplayed: classes.bulkActions,
        }}
        className={classes.listWrapper}
        pagination={
          <ExlyTablePagination
            hasMobileFooter={props.hasMobileFooter}
            isFullLayout={props?.footerProps?.isFullLayout}
            borderedTop={true}
            perPageOptions={perPageOptions}
            entityName={props.entityName}
          />
        }
      >
        <ThemeProvider theme={creatorToolTheme}>
          <ExlyContextTable {...props} />
        </ThemeProvider>
      </List>
    </div>
  );
};

export default ExlyTableWrapper;
