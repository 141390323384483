import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs } from "@my-scoot/component-library-legacy";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { is_empty } from "features/Common/utils/common.utils";

const useStyles = makeStyles((theme) => ({
  tabsWrapperRoot: {
    marginBottom: "12px",
  },
  tabsWrapperScroller: {
    borderBottom: ({ showTabsBottomBorder }) =>
      showTabsBottomBorder &&
      `2px solid ${theme?.palette?.secondary?.shade_100}`,
    margin: "-2px -16px 0 -16px",
  },
  tabs_scroller: {
    borderBottom: "none !important",
  },
  marginTop: {
    marginTop: "16px",
  },
}));

const TabsWrapper = ({ children, showTabsBottomBorder }) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop, showTabsBottomBorder });
  return (
    <div className={classes.tabsWrapperRoot}>
      {children}
      {!isDesktop && <div className={classes.tabsWrapperScroller} />}
    </div>
  );
};

const TabLayout = ({
  children,
  selectedTab,
  tabConfig,
  tabProps,
  subTabSelected,
  showTabsBottomBorder = true,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [selected, setSelected] = useState(
    selectedTab ? selectedTab : tabConfig[0]?.value
  );
  const [subTabValue, setSubTabValue] = useState(
    subTabSelected ? subTabSelected : tabConfig[0]?.subTabs?.[0]?.value || ""
  );
  const handleChange = (event, newValue) => {
    const currentSelected = tabConfig.find((tab) => tab.value === newValue);
    setSelected(newValue);
    if (!is_empty(currentSelected?.subTabs)) {
      setSubTabValue(currentSelected?.subTabs?.[0]?.value || "");
    }
    history.replace(currentSelected?.path);
  };

  const handleSubTabChange = (event, newValue) => {
    const currentSelected = tabConfig.find((tab) => tab.value === selected);
    const newSubTab = currentSelected?.subTabs?.find(
      (subTab) => subTab.value === newValue
    );
    setSubTabValue(newValue);
    history.push(newSubTab?.path);
  };

  const subTabsList = useMemo(() => {
    return tabConfig.find((tab) => tab.value === selected) ?? [];
  }, [selected]);

  return (
    <>
      <TabsWrapper showTabsBottomBorder={showTabsBottomBorder}>
        <Tabs
          className={classes.tabsWrapper}
          tabs={tabConfig}
          value={selected}
          onChange={handleChange}
          tabVariant="outlined"
          {...tabProps}
        />
      </TabsWrapper>
      {!is_empty(subTabsList) && (
        <div className={classes.marginTop}>
          <Tabs
            key="level-two"
            value={subTabValue}
            tabVariant="filled_primary"
            onChange={handleSubTabChange}
            tabs={subTabsList.subTabs}
            classes={{ scroller: classes.tabs_scroller }}
          />
        </div>
      )}
      {children}
    </>
  );
};

export default TabLayout;
