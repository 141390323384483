import React from "react";
import Select from "react-select";

const ExlySelect = (props) => {
  const { input, meta, ...rest } = props;

  return (
    <>
      <Select {...input} {...rest} />
      {meta.error && meta.touched && (
        <small className="danger_text mt-2 d-block">{meta.error}</small>
      )}
    </>
  );
};

export default ExlySelect;
