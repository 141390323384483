import React from "react";
import TimePicker from "common/Components/ExlyTimePicker";

const ExlyTimePicker = (props) => {
  const { input, meta, onChange, ...rest } = props;

  return (
    <TimePicker
      error={meta.error && meta.touched}
      type="time"
      helperText={meta.error && meta.touched ? meta.error : null}
      {...input}
      onChange={(e) => {
        if (input.onChange) {
          input.onChange(e); // final-form's onChange
        }
        if (onChange) {
          //props.onChange
          onChange(e, input.name);
        }
      }}
      {...rest}
    />
  );
};

export default ExlyTimePicker;
