import React from "react";
import classnames from "classnames";
import { Checkbox } from "@my-scoot/component-library-legacy";
import { useStyles } from "./index.styles";

const ExlyCheckbox = (props) => {
  const classes = useStyles();

  const {
    input = {},
    meta = {},
    onChange,
    label,
    helperNode,
    outerWrapperClassName,
    labelClassName,
    ...rest
  } = props;

  return (
    <div className={classnames(classes.root, outerWrapperClassName)}>
      <Checkbox
        error={meta.error && meta.touched}
        helperText={meta.error && meta.touched ? meta.error : null}
        checked={input.value}
        {...rest}
        autocomplete={"off"}
        onChange={(e) => {
          e.target.value = e.target.checked;
          if (input?.onChange) input.onChange(e);
          if (onChange) {
            onChange(e.target.checked, input.name);
          }
        }}
      />
      <label
        htmlFor={props.id}
        className={classnames(classes.label, labelClassName)}
      >
        {label}
      </label>
      {helperNode}
    </div>
  );
};

export default ExlyCheckbox;
