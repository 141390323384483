import React from "react";
import { is_empty } from "utils/validations";
import moduleStyle from "./UTMInfoField.module.css";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import classnames from "classnames";

export const UTMInfoField = ({ record }) => {
  const isDesktop = useDesktopMediaQuery();
  const relevantEntries = Object.entries(record.utm_info || {}).filter(
    ([, value]) => !is_empty(value)
  );

  if (is_empty(relevantEntries)) {
    return "-";
  }
  return (
    <div>
      {relevantEntries.map(([key, value]) => {
        return (
          <div
            key={`${key}_${record?.uuid || record?.id}`}
            className={`${moduleStyle.container}`}
          >
            <span
              className={classnames(
                `mr-1 font-weight-bold text-capitalize`,
                moduleStyle.keyText
              )}
            >
              {key}&nbsp;{isDesktop ? ":" : ""}&nbsp;
            </span>
            <span className={moduleStyle.valueText}>{value}</span>
          </div>
        );
      })}
    </div>
  );
};
