const numberFields = [
  "price_per_head",
  "price_international",
  "buffer_duration",
  "duration",
  "max_spots",
  "course_end_magnitude",
  "slot_range",
  "start_time_exception",
];

export function getChangedVales({ from, to }) {
  const changedKeys = Object.keys(from).filter((key) => {
    return to[key] !== from[key];
  });

  const changedValues = changedKeys.reduce((acc, key) => {
    if (numberFields.includes(key)) {
      acc[key] = Number(to[key]);
    } else {
      acc[key] = to[key];
    }

    if (key === "venue_details") {
      acc["venue_details"] = to["venue_details"];
    }

    return acc;
  }, {});

  return changedValues;
}

/**
 * to check if form values are changed
 * @param {*} from form initialValue object
 * @param {*} to current form value object
 * @returns boolean
 */
export const isFormDirty = (from, to) => {
  const updated_values = {
    ...getChangedVales({ from, to }),
  };
  return Object.keys(updated_values).length !== 0;
};

export function removePrefilledFromURL() {
  const url = window.location.href;
  const newURL = url.split("?")[0];

  let params = url.split("?")[1]?.split("&");
  if (params) {
    params = params.filter((param) => !param.includes("prefilled"));
    let withoutPrefilled = params.join("&");
    window.history.pushState("", "", newURL + "?" + withoutPrefilled);
  }
}

export function scrollMainContentToTop() {
  const mainLayout = document.querySelector("#mainLayout");

  if (mainLayout) {
    mainLayout.scrollTop = 0;
  }
}

export const updateQueryStringParameter = (uri, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

export const isDeviceIOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

/**
 * @returns pluralised `word` by appending an "s" when the `count > 0`
 */
export const VOWELS = new Set(["a", "e", "i", "o", "u"]);
