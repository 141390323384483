import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    error: {
      marginTop: "7.28px",
      fontSize: "11px",
      color: theme?.palette?.basic?.danger_red,
    },
  }),
  { name: "CreatorTool" }
);
