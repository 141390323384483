import React from "react";
import PhoneInput from "react-phone-input-2";

// import "react-phone-input-2/lib/style.css";
// use this css for proper react-phone-input-2 dropdown styles
// issue link: https://exly.height.app/T-6859
// TODO: add this css to this component itself if you are confident enough about impact

const ExlyPhoneInput = (props) => {
  const { input, meta, ...rest } = props;
  const { renderError } = props;
  return (
    <>
      <PhoneInput
        country={"in"}
        error={meta.error && meta.touched}
        helperText={meta.error && meta.touched ? meta.error : null}
        buttonStyle={{ borderRadius: "5px 0 0 5px", width: "44px" }}
        countryCodeEditable={false}
        inputStyle={{ width: "100%", border: "1px solid #e5e3dd" }}
        copyNumbersOnly={false}
        {...input}
        {...rest}
      />
      {meta.error && meta.touched && (
        <>
          {renderError ? (
            renderError(meta.error)
          ) : (
            <small className="text-danger mt-1 d-block">{meta.error}</small>
          )}{" "}
        </>
      )}
    </>
  );
};

export default ExlyPhoneInput;
