import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "14px",
    color: theme?.palette?.secondary?.main,
    "& label": {
      margin: 0,
      lineHeight: "17px",
    },
  },
  label: {
    cursor: "pointer",
    userSelect: "none",
  },
}));
