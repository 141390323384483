import React from "react";
import { Select } from "@my-scoot/component-library-legacy";

const ExlyCustomSelect = (props) => {
  const { input, meta, helperText, onChange, ...rest } = props;

  return (
    <Select
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : helperText}
      {...input}
      {...rest}
      value={input?.value || (input?.multiple ? [] : "")}
      onChange={(value) => {
        input.onChange(value); //final-form's onChange
        if (onChange) {
          //props.onChange
          onChange(value);
        }
      }}
    />
  );
};

export default ExlyCustomSelect;
