import React from "react";
import { Input } from "@my-scoot/component-library-legacy";
import { is_empty } from "utils/validations";
import moduleStyle from "./ExlyInput.module.css";

const ExlyInput = (props) => {
  const {
    fieldLabel,
    input = {},
    meta = {},
    onChange,
    helperText,
    hideErrorText,
    ...rest
  } = props;

  // @Dev: By default the validation will be on touch (visit + blur) event
  // once validateOnSubmit is passed as false the validation will be on submitFailed (on submit)
  const { validateOnSubmit } = rest;
  let finalValidateCondn = meta.touched;
  if (validateOnSubmit) {
    finalValidateCondn = meta.submitFailed;
  }

  return (
    <>
      {!is_empty(fieldLabel) && (
        <div className={`mb-1`}>
          <span className={`${moduleStyle.labelField} ${fieldLabel.textClass}`}>
            {fieldLabel.text}
          </span>
          {fieldLabel.required && <span className={`text-danger`}>*</span>}
        </div>
      )}
      <Input
        error={(meta.error || meta.submitError) && finalValidateCondn}
        helperText={
          (meta.error || meta.submitError) &&
          finalValidateCondn &&
          !hideErrorText
            ? meta.error || meta.submitError
            : helperText
        }
        {...input}
        {...rest}
        onChange={(e) => {
          if (input.onChange) {
            input.onChange(e); //final-form's onChange
          }
          if (onChange) {
            //props.onChange
            onChange(e.target.value, input.name);
          }
        }}
      />
    </>
  );
};

export default ExlyInput;
