import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  RichTextEditorModal,
  RichTextEditorUtils,
} from "@my-scoot/component-library-legacy";
import { handleFileUpload } from "features/Common/modules/File/utils/File";

const { emptyEditorState } = RichTextEditorUtils;

const ExlyRichTextEditorModal = (props) => {
  const { input, meta, ...rest } = props;
  const isDesktop = useMediaQuery("(min-width: 767px)");

  return (
    <RichTextEditorModal
      fullScreen={!isDesktop}
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : null}
      editorState={input.value || emptyEditorState}
      onEditorStateChange={input.onChange}
      minHeight={isDesktop && "50vh"}
      maxHeight={isDesktop && "50vh"}
      imageUploadCallback={handleFileUpload}
      {...rest}
    />
  );
};

export default ExlyRichTextEditorModal;
