import React from "react";
import { ComplexDropdown } from "@my-scoot/component-library-legacy";

// Form components use common component and add final form layer on them
// Here this component wraps final form layer over ComplexDropdown

const ExlyComplexDropdown = (props) => {
  const { input, meta, onChange, className, ...rest } = props;
  const { onChange: inputOnChange, value, ...restInputProps } = input;
  return (
    <div className={className}>
      <ComplexDropdown
        error={meta.error && meta.touched}
        helperText={meta.error && meta.touched ? meta.error : null}
        value={value || (rest.checked || input.multiple ? [] : "")}
        {...restInputProps}
        {...rest}
        handleChange={(value) => {
          inputOnChange({ target: { value } });
          if (onChange) onChange(value);
        }}
      />
    </div>
  );
};

export default ExlyComplexDropdown;
