import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  progressRoot: {
    width: "100%",
    padding: ({ isDesktop }) => !isDesktop && "0 16px",
  },
  previewBtn: {
    color: theme?.palette?.primary?.main,
  },
  secondaryBtn: {
    color: theme.palette.basic.black,
    marginRight: "16px",
  },
  mobilePreviewBtn: {
    textDecoration: "underline",
    paddingLeft: "0",
    minWidth: 0,
  },
}));
