import { isFormDirty } from "common/util";
import React from "react";
import { useForm, useFormState } from "react-final-form";
import RouteLeavingPrompt from "ui/widgets/routeLeavingPrompt";

export const LeavingPrompt = ({ handleNext }) => {
  const form = useForm();
  const formState = useFormState();

  // href value changes as soon as we press back; so had to use it so that the variable value remains same even if the href value changes
  const currentHref = React.useMemo(() => window.location.href, []);

  const [showLeavingPrompt, setShowLeavingPrompt] = React.useState(false);
  React.useEffect(() => {
    const isDirty = isFormDirty(formState?.initialValues, formState?.values);
    setShowLeavingPrompt(isDirty);
  }, [formState?.values]);

  React.useEffect(() => {
    if (formState?.values?.questionnaire) {
      setShowLeavingPrompt(false);
    }
  }, [formState?.values?.questionnaire]);

  return (
    <>
      {showLeavingPrompt && (
        <RouteLeavingPrompt
          when={showLeavingPrompt}
          primaryAction={() => {
            handleNext ? handleNext(formState?.values) : form.submit();
            if (window.location.href !== currentHref) {
              window.location.href = currentHref;
            }
          }}
          handleClose={() => {
            // when pressing browser back the href changes to previous state; so to keep href to be currentHref on closing prompt
            if (window.location.href !== currentHref) {
              window.location.href = currentHref;
            }
          }}
          secondaryAction={(location) => {
            window.location.href = `${window.location.origin}/#${location?.pathname}${location?.search}`;
            window.location.reload();
          }}
          sourceUrl={`${window.location}`}
          shouldBlockNavigation={(location) => {
            // not to show prmopt if use is navigating within the form pages
            if (location?.pathname && currentHref.includes(location.pathname)) {
              return false;
            }
            return true;
          }}
        />
      )}
    </>
  );
};
