import React, { useState, useEffect } from "react";
import { Button, Progress } from "@my-scoot/component-library-legacy";
import { Form } from "react-final-form";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { getParameterByName } from "utils/Utils";
import arrayMutators from "final-form-arrays";
import { updateQueryStringParameter } from "common/util";
import { useStyles } from "./ExlyWizard.module";
import { notification_color_keys, useNotifications } from "utils/hooks";
import planConstants from "schedule-v2/Plans/Constants";
import { LeavingPrompt } from "./LeavingPrompt";
import { is_empty } from "utils/validations";
import AlertStrip from "features/Listings/modules/AlertStrip/AlertStrip";
import { alertMessage } from "features/RecordedContent/RecordedContent.constants";
import LearnMoreTitle from "features/Common/modules/LearnMore/modules/LearnMoreTitle/LearnMoreTitle";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import LearnMoreStrip from "features/Common/modules/LearnMore/modules/LearnMoreStrip/LearnMoreStrip";
import styles from "./ExlyWizard.module.css";

const ExlyWizard = (props) => {
  const {
    publishBtnText = "Publish",
    children,
    steps,
    page: initialPage,
    initialValues,
    onNext,
    backActionText,
    onPrevious,
    onShowPreview,
    nextButton = props.initialValues?.is_draft ? "Next" : "Save & Next",
    progressWrapperClass,
    buttonWrapperClass,
    navWrapperClass,
    dataSubmitting = false,
    progressProps = {},
    hideButtons = false,
    customSaveFormats = [],
    formProps = {},
    formatFormErrorMessage,
    showSecondaryActions = true,
    secondaryActions = [],
    metaData = {},
    showAlertStrip,
    learnMoreHref,
    about,
  } = props;
  const isDesktop = useIsDesktop();

  const classes = useStyles({ isDesktop });

  const { validate: formValidate, ...restFormProps } = formProps;

  const [page, setPage] = useState(initialPage || 0);
  const [values, setValues] = useState({});
  const isListingDraft = values.status === 0;

  const isPlan = metaData?.isPlan;

  const isPlanDraft = isPlan
    ? values.status === planConstants.STATUS_ENUM.DRAFT
    : false;

  // if listing is draft listing, progress bar will be linear else non linear
  let isNonLinear = !isListingDraft;

  //in case of plan if plan is draft plan, progress bar will be linear else non linear
  if (isPlan) {
    isNonLinear = !isPlanDraft;
  }

  const [completedOptions] = useState(() =>
    steps.map((step) => ({
      title: step,
      description: step,
      completed: true,
    }))
  );

  const activePage = React.Children.toArray(children)[page];
  const isLastPage = page === React.Children.count(children) - 1;

  const { notify } = useNotifications();

  const handleNext = (values, moveToNext = true) => {
    const valuesToBeSaved = { ...values };
    // converting UI values to values that will be saved to db
    customSaveFormats.forEach(({ key, preSave }) => {
      valuesToBeSaved[key] = preSave(valuesToBeSaved[key]);
    });
    setValues(values);
    onNext &&
      onNext({
        page,
        isLastPage,
        values: valuesToBeSaved,
        callback: () => {
          if (moveToNext) {
            setPage(Math.min(page + 1, React.Children.count(children)));
          }
        },
        moveToNext,
      });
  };

  useEffect(() => {
    const customInitialValues = { ...initialValues };
    // convert db saved data to UI required data
    customSaveFormats.forEach(({ key, postSave }) => {
      customInitialValues[key] = postSave(customInitialValues[key]);
    });
    setValues(customInitialValues);
  }, [initialValues]);

  useEffect(() => {
    const newpage = getParameterByName("page");
    setPage(Number(newpage));
  }, [window.location.href]);

  const onClickProgress = (e, step) => {
    if (step === page) return;
    if (
      isNonLinear || // for non draft go anywhere
      step < page // for draft allowing backward navigation
    ) {
      setPage(step);
      onPrevious && onPrevious(step + 1, values);
    }
  };

  return (
    <>
      <Form
        initialValues={values}
        onSubmit={handleNext}
        mutators={{
          ...arrayMutators,
        }}
        validate={(formValues) => formValidate?.(formValues, page)}
        {...restFormProps}
      >
        {({ handleSubmit, invalid, errors, values }) => (
          <form
            onSubmit={(event) => {
              if (invalid) {
                notify(
                  formatFormErrorMessage?.(errors) ||
                    "Please fill the mandatory details",
                  "error"
                );
              }

              handleSubmit(event);
            }}
          >
            <div
              className={`${progressWrapperClass} ${
                showAlertStrip ? "pb-0 pb-md-3" : ""
              }`}
            >
              <div
                className={navWrapperClass}
                onClick={() =>
                  onPrevious ? onPrevious(page) : window.history.back()
                }
              >
                <ArrowBack className="mr-2" />
                {isDesktop && learnMoreHref ? (
                  <LearnMoreTitle
                    pageTitle={backActionText}
                    href={learnMoreHref}
                    about={about}
                  />
                ) : (
                  <span>{backActionText}</span>
                )}
              </div>
              <div className={classes.progressRoot}>
                {!isDesktop && learnMoreHref ? (
                  <LearnMoreStrip
                    href={learnMoreHref}
                    about={about}
                    className={styles.learnMoreStrip}
                  />
                ) : null}
                <Progress
                  options={
                    isListingDraft || isPlanDraft ? steps : completedOptions
                  }
                  activeStep={page}
                  variant="alternate"
                  nonLinear={isNonLinear}
                  onClick={onClickProgress}
                  stepIconSize="large"
                  {...progressProps}
                  hideProgress={true}
                />
              </div>
              {!hideButtons && (
                <div className={buttonWrapperClass}>
                  <span className="d-none d-md-block mr-3">
                    {isLastPage && (
                      <Button
                        size="medium"
                        color="subtlePrimary"
                        onClick={() => {
                          setPage(0);
                          window.location.href = updateQueryStringParameter(
                            window.location.href,
                            "page",
                            0
                          );
                        }}
                        className={classes.secondaryBtn}
                      >
                        Edit
                      </Button>
                    )}
                  </span>
                  <span className="d-md-none">
                    <Button
                      color="subtlePrimary"
                      onClick={onShowPreview}
                      className={classes.mobilePreviewBtn}
                    >
                      Preview
                    </Button>
                  </span>
                  {showSecondaryActions &&
                    secondaryActions?.map(
                      ({ label, onClick, ...restProps }) => (
                        <Button
                          key={label}
                          disabled={dataSubmitting}
                          color="primary"
                          size="medium"
                          loading={dataSubmitting}
                          onClick={() => onClick?.(values)}
                          className="mr-2 text-nowrap"
                          {...restProps}
                        >
                          {label}
                        </Button>
                      )
                    )}
                  {!isLastPage && (
                    <Button
                      disabled={dataSubmitting}
                      type="submit"
                      color="primary"
                      size="medium"
                      loading={dataSubmitting}
                      className="text-nowrap"
                    >
                      {nextButton}
                    </Button>
                  )}
                  {isLastPage && (
                    <Button
                      disabled={dataSubmitting}
                      type="submit"
                      color="primary"
                      size="medium"
                      loading={dataSubmitting}
                    >
                      {publishBtnText}
                    </Button>
                  )}
                </div>
              )}
              {showAlertStrip ? (
                <AlertStrip
                  text={alertMessage.text}
                  toolTipText={alertMessage.tooltipText}
                />
              ) : null}
            </div>
            {activePage}

            {/* LeavingPrompt only on first page */}
            {page === 0 && (
              <LeavingPrompt
                handleNext={(values) => {
                  let error = formValidate?.(values, page);
                  if (!is_empty(error)) {
                    notify(
                      Object.values(error)[0],
                      notification_color_keys.error
                    );
                    return;
                  }
                  handleNext(values, false);
                }}
                initialValues={initialValues}
                currentHref={window.location.href}
              />
            )}
          </form>
        )}
      </Form>
    </>
  );
};

export default ExlyWizard;
