import constants from "constants/constants";
import { CUSTOM_CODE_ALLOWED_TAGS_REGEX } from "features/CustomCode/CustomCode.constants";
import { containsOnlySpaces } from "utils/validations";

export const required = (value) =>
  value || value == 0 ? undefined : "Required";

export const validateNonEmptyString = (value) => {
  if (value === undefined || value === null || containsOnlySpaces(value)) {
    return "The string is required and cannot be empty.";
  }
  return undefined;
};

export const requiredWithMessage = (message) => (value) =>
  value || value == 0 ? undefined : message;
export const minLength = (length, msg) => (value) =>
  value?.length >= length ? undefined : msg;
export const maxLength = (length, msg) => (value) => {
  if (!value) return;
  return value?.length <= length ? undefined : msg;
};
export const alphaNumeric = (value) =>
  /^[a-z0-9]+$/i.test(value) ? undefined : "Must be alphanumeric";
export const atLeastOneAlphabet = (value) =>
  /[a-z]+/i.test(value) ? undefined : "Must have at least 1 alphabet";
export const atLeastOneNumber = (value) =>
  /[0-9]+/.test(value) ? undefined : "Must have at least 1 number";

export const minValue =
  (min, customText = "Must be greater than or equal to") =>
  (value) =>
    !isNaN(value) && Number(value) < min ? `${customText} ${min}` : undefined;
export const maxValue =
  (max, customText = "Must be less than or equal to") =>
  (value) =>
    !isNaN(value) && Number(value) > max ? `${customText} ${max}` : undefined;

export const noSpaces =
  (errorMessage = "Invalid value") =>
  (value) =>
    value && value.length > 0 && value.trim().length > 0
      ? undefined
      : errorMessage;

export const isPositiveInteger = (value) => {
  const numValue = Number(value);

  if (!value) return;
  if (isNaN(numValue) || !Number.isInteger(numValue) || numValue < 1)
    return constants.error.positiveIntgersAllowed;
};

export const isCustomCodeAllowed = (htmlStringHere) => {
  const isValid = CUSTOM_CODE_ALLOWED_TAGS_REGEX.test(htmlStringHere);
  return isValid
    ? undefined
    : "Only iframe, meta, link(self-closing), script, style and noscript tags are allowed";
};

/* identity
 *This wraps react-final-form's <Field/> component.
 * The identity function ensures form values never get set to null,
 * but rather, empty strings.
 *
 * See https://github.com/final-form/react-final-form/issues/130
 */

export const identity = (value) => value;

// can be alpha numberic or can have - character
export const isValidUrlSlug = (str) => {
  return /^[a-z0-9A-Z-]+$/i.test(str)
    ? undefined
    : "Please use 0-9, a-z, A-Z or - in your url";
};

export const isValidMetaTag = (htmlStringHere) => {
  const regex = /^((<meta[\w\d\s"'#=-]*?\/?>)|\s)*$/;
  const isValid = regex.test(htmlStringHere);
  return isValid ? undefined : "Only meta tags are allowed";
};

export const validateRegex = (regex, message) => {
  return (value) => {
    const isValid = regex.test(value);
    return isValid ? undefined : message;
  };
};
/**
 *
 * @param {*} value input
 * @returns error message if value is not an alphabet or _
 */
export const isAlphaNumberUnderScore = (value) => {
  const regex = /^[a-zA-Z0-9_ ]+$/;
  const isValid = regex.test(value);
  return isValid ? undefined : "Please enter only alphabets,numbers or _";
};

export const finalFormParsers = {
  number: (value) =>
    (value && !isNaN(value)) || value === 0 ? +value : undefined,
};

export const isValidWhatsappGroupLink = (msg) => (value) => {
  const whatsappGroupRegex =
    /^(https?:\/\/)?chat\.whatsapp\.com\/(?:invite\/)?([a-zA-Z0-9\-_]+)$/;
  const isValid = whatsappGroupRegex.test(value);
  return isValid ? undefined : msg;
};

export const isNumber = (input) => {
  // Numberic and decimal
  var reg = new RegExp(/^\d*\.?\d*$/);
  return reg.test(input);
};

export const isNumberType = (input) => {
  return typeof input === "number";
};
