import React from "react";
import PhotoWidgetV2 from "ui/widgets/photoUploader/PhotoWidgetV2";

const ExlyPhotoWidget = (props) => {
  const { scheduleType, input, ...rest } = props;

  return (
    <PhotoWidgetV2
      selected={input.value}
      onChange={input.onChange}
      {...rest}
      scheduleType={scheduleType}
    />
  );
};

export default ExlyPhotoWidget;
